import { russianDefaultPaymentCards } from "app/constants/CurrencyConstants";

export const reducer: typeof window.kinosite.reducer = {
	cinema: {
		address: 'г. Омск, ул. 70 лет Октября, 25/2',
		place: null,
		phoneNumbers: [],
		facebook: null,
		instagram: null,
		vk: null,
		twitter: null,
		odnoklassniki: null,
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: russianDefaultPaymentCards,
		},
		maps: null,
		selectButton: {
			options: [
				{
					value: 'https://konti-kino.ru',
					title: 'Континент',
				},
				{
					value: 'https://rubin-omsk.ru',
					title: 'Рубин',
				},
			],
			selectedOptionValue: 'https://konti-kino.ru',
		},
		soonPageIsEnabled: false,
	},
	feedback: true,
};
